<template>
  <div class="container-web-app d-flex justify-center align-center">
    <div class="lds-ellipsis">
      <div :key="i" v-for="i in 4"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductLoading",
  methods: {
    setColor(isDark) {
      this.$el.style.setProperty("--bg-color", isDark ? "black" : "white");
    }
  },
  mounted() {
    const darkModeInstance = matchMedia("(prefers-color-scheme: dark)");
    this.setColor(darkModeInstance.matches);

    // onchange instead of addEventListener() so it works cross-browsers
    darkModeInstance.onchange = ({ matches }) => {
      this.setColor(matches);
    };
  }
};
</script>

<style scoped>
.container-web-app {
  --bg-color: white;
  height: 100%;
}

.lds-ellipsis {
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
  background: var(--bg-color);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
</style>
