<template>
  <v-col lg="4" md="6" sm="12" xl="3">
    <v-card
      :dark="isDarkModeEnabled"
      :data-product-container="product.pageUrl"
      :light="!isDarkModeEnabled"
      class="pa-5 product__card"
      elevation="4"
      height="100%"
    >
      <transition mode="out-in" name="content-reveal">
        <article v-if="!isProductOpen(product.pageUrl)" class="d-flex flex-column height--full">
          <ProductHeader
            :icon="product.icon"
            :icon-color="product.iconColor"
            :name="product.name"
            :path-name="product.pageUrl"
          />
          <ProductDescription :chrome-item-url="chromeItemId" :description="description" class="mt-3 flex-grow-1" />
          <ProductLinks
            :icon="product.icon"
            :icon-color="product.iconColor"
            :page-url="product.pageUrl"
            :platforms="product.platforms"
            :screenshots-info="product.screenshotsInfo"
          />
        </article>
        <main v-else-if="!product.platforms[0].isFullscreen">
          <component :is="component" />
        </main>
      </transition>
    </v-card>
  </v-col>
</template>

<script>
import ProductHeader from "@/components/ProductHeader";
import ProductDescription from "@/components/ProductDescription";
import ProductLinks from "@/components/ProductLinks";
import ProductLoading from "@/components/ProductLoading";
import { mapGetters } from "vuex";

export default {
  name: "Product",
  components: {
    ProductLoading,
    ProductHeader,
    ProductDescription,
    ProductLinks
  },
  props: {
    product: Object
  },
  data() {
    return {
      usersCount: 0
    };
  },
  computed: {
    ...mapGetters(["isProductOpen", "isProductActive", "toComponentName"]),
    component() {
      const product = this.toComponentName(this.product.pageUrl);
      return () => ({
        component: import(`@/views/products/${product}`),
        loading: ProductLoading
      });
    },
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.product.pageUrl);
    },
    chromeItemId() {
      const product = this.product.platforms.find(({ name }) => name === "chrome");
      return product?.url ?? "";
    },
    description() {
      if (this.product.pageUrl !== "youtube-downloader") {
        return this.product.description;
      }

      return `${this.product.description}
You can run it until <a href="https://developer.chrome.com/docs/extensions/mv3/mv2-sunset" target="_blank" class="${
        this.isDarkModeEnabled ? "link--dark" : "link--light"
      }">June 2024</a>,
so hurry up!`;
    }
  }
};
</script>

<style scoped>
.height--full {
  height: 100%;
}

/*noinspection CssUnusedSymbol*/
.content-reveal-enter,
.content-reveal-leave-to {
  max-height: 0;
  opacity: 0;
}

/*noinspection CssUnusedSymbol*/
.content-reveal-enter-to,
.content-reveal-leave {
  max-height: 80vh;
  opacity: 1;
}

/*noinspection CssUnusedSymbol*/
.content-reveal-enter-active {
  transition: max-height 0.25s, opacity 0.5s;
}

/*noinspection CssUnusedSymbol*/
.content-reveal-leave-active {
  transition: max-height 0.25s 0.2s, opacity 0.5s;
}

.product__card {
  transition: background-color 0.3s;
}

/*noinspection CssUnusedSymbol*/
.v-card {
  border: 1px solid lightgray;
  border-radius: 8px !important;
}

@media (prefers-color-scheme: dark) {
  /*noinspection CssUnusedSymbol*/
  .v-card {
    border-color: rgba(211, 211, 211, 0.4);
  }
}
</style>
