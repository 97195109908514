<template>
  <v-sheet class="text-center pt-2">
    <h3 class="mb-2">
      <Url :dark-mode="this.$vuetify.theme.isDark" :href="link">
        {{ product.name }}
      </Url>
    </h3>
    <v-carousel
      v-model="iScreenshotCurrent"
      :hide-delimiters="screenshots.length === 1"
      :show-arrows="screenshots.length > 1"
      height="400"
    >
      <v-carousel-item v-for="screenshot of screenshots" :key="screenshot">
        <img :src="`assets/img/screenshots/${product.pageUrl}/${screenshot}`" alt="Screenshot" class="screenshot" />
      </v-carousel-item>
    </v-carousel>
  </v-sheet>
</template>

<script>
import Url from "@/components/Url";

export default {
  name: "Screenshots",
  components: { Url },
  data() {
    return {
      screenshots: [],
      iScreenshotCurrent: 0
    };
  },
  watch: {
    "$store.state.screenshotsInfo.isShow"(isShow) {
      if (isShow) {
        this.prepareToGenerateScreenshots();
      } else {
        this.$store.commit("setProductActive", this.$route.path.substring(1));
      }
    }
  },
  methods: {
    prepareToGenerateScreenshots() {
      const {
        product: { productType, filenames }
      } = this.$store.state.screenshotsInfo;
      switch (productType) {
        case "browser-extension": {
          this.screenshots = this.generateBrowserScreenshots(filenames);
        }
      }
      this.$nextTick().then(() => {
        this.iScreenshotCurrent = 0;
      });
    },
    /**
     * @param {string[]} filenames
     * @return {string[]}
     */
    generateBrowserScreenshots(filenames) {
      const screenshotsUniversal = this.getUniversalScreenshots(filenames);
      const screenshotsBrowserSpecific = this.getBrowserSpecificScreenshots(filenames);

      const screenshotsTotal = [...screenshotsUniversal, ...screenshotsBrowserSpecific];

      // noinspection UnnecessaryLocalVariableJS
      const screenshotsOrdered = screenshotsTotal.sort((a, b) => a.match(/\d+/)[0] - b.match(/\d+/)[0]);
      return screenshotsOrdered;
    },
    /**
     * @param {string[]} filenames
     * @returns {string[]}
     */
    getUniversalScreenshots(filenames) {
      return filenames.filter(filename => !this.browsersAvailable.find(browser => filename.includes(browser)));
    },
    /**
     * @param {string[]} filenames
     * @returns {string[]}
     */
    getBrowserSpecificScreenshots(filenames) {
      return filenames.filter(filename => filename.includes(this.browsersAvailable[0]));
    }
  },
  computed: {
    browsersAvailable() {
      const platforms = this.product.platforms[0].isExternal ? this.product.platforms : this.product.platforms.slice(1);
      let orderedServices = this.$store.getters.getOrderedServices(platforms);

      // Currently used for YouTube Downloader
      if (orderedServices[0].name === "github" || orderedServices.length === 0) {
        orderedServices = this.product.platforms.filter(({ url }) => Boolean(url));
      }
      return orderedServices.map(({ name }) => name);
    },
    product() {
      const productPageUrl = this.$store.state.screenshotsInfo.product.filenames[0].split("_")[0];
      return this.$store.state.products.find(({ pageUrl }) => pageUrl === productPageUrl);
    },
    link() {
      const browserCurrent = this.product.platforms.find(({ name }) => name === this.browsersAvailable[0]);
      return browserCurrent?.url;
    }
  },
  created() {
    this.prepareToGenerateScreenshots();
  }
};
</script>

<style>
.screenshot {
  max-height: 100%;
  max-width: 100%;
}
</style>
