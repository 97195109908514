<template>
  <v-app :dark="$vuetify.theme.isDark" :light="!$vuetify.theme.isDark">
    <v-dialog
      v-model="$store.state.screenshotsInfo.isShow"
      max-width="640"
      @click:outside="$store.state.screenshotsInfo.isShow = false"
    >
      <Screenshots />
    </v-dialog>

    <header class="mb-2">
      <About />
    </header>

    <v-tabs :background-color="backgroundTabs" :value="$store.state.activeTab" hide-slider>
      <v-spacer />
      <v-tab v-if="iProductFullscreenActive > -1" key="tab-main" @click="navigateBack" @keypress.space="navigateBack">
        <v-icon :color="colorArrow">{{ mdiArrowLeft }}</v-icon>
      </v-tab>

      <v-tab
        v-for="(product, i) of productsFullscreen"
        :key="product.pageUrl"
        v-if="iProductFullscreenActive === i"
        :class="{
          'white--text': $vuetify.theme.isDark,
          'black--text': !$vuetify.theme.isDark
        }"
        class="text-none"
      >
        {{ productsFullscreen[iProductFullscreenActive].name }}
      </v-tab>

      <v-spacer />

      <v-tabs-items v-if="products.length > 0" :touchless="true" :value="$store.state.activeTab">
        <v-tab-item class="tabs-background">
          <h1 class="text-center mb-7 font--poppins">My products</h1>

          <transition-group class="d-flex flex-wrap" name="products-list" tag="main">
            <Product v-for="product of products" :key="product.pageUrl" :product="product" />
          </transition-group>
        </v-tab-item>

        <v-tab-item
          v-for="{ pageUrl } of productsFullscreen"
          v-show="isProductActive(pageUrl)"
          :key="pageUrl"
          class="tabs-background"
        >
          <v-card class="mx-auto container-web-app--fullscreen" max-width="600">
            <ComponentFullscreen :page-url="pageUrl" />
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div v-else>
        <v-tab-item>Loading products...</v-tab-item>
      </div>
    </v-tabs>
  </v-app>
</template>

<script>
import About from "@/About";
import Product from "@/components/Product";
import ComponentFullscreen from "@/components/ComponentFullscreen";
import Screenshots from "@/components/Screenshots";

import { mapGetters, mapMutations, mapState } from "vuex";
import { mdiArrowLeft } from "@mdi/js";

export default {
  name: "App",
  components: { Screenshots, ComponentFullscreen, Product, About },
  data() {
    return {
      products: [],
      mdiArrowLeft
    };
  },
  computed: {
    ...mapState(["productActive", "productsOpen"]),
    ...mapGetters(["isProductActive", "productsFullscreen"]),
    iProductFullscreenActive() {
      return this.productsFullscreen.findIndex(({ pageUrl }) => pageUrl === this.productActive);
    },
    backgroundTabs() {
      if (this.$vuetify.theme.isDark) {
        return "#121212";
      }
      return "#FFFFFF";
    },
    colorArrow() {
      return this.$vuetify.theme.isDark ? "#FFFFFF" : "";
    }
  },
  methods: {
    ...mapMutations(["setProductsOpen"]),
    applyDarkTheme() {
      if (!window.matchMedia) {
        return;
      }
      const instanceDarkMode = matchMedia("(prefers-color-scheme: dark)");
      this.$vuetify.theme.isDark = instanceDarkMode.matches;

      // onchange instead of addEventListener() so it works cross-browsers
      instanceDarkMode.onchange = ({ matches: isDarkMode }) => {
        this.$vuetify.theme.isDark = isDarkMode;
      };
    },
    navigateBack() {
      if (history.length > 1) {
        this.$router.go(-1);
      } else {
        this.$router.push("/");
      }
    }
  },
  created() {
    this.applyDarkTheme();

    this.axios("/data/products.json").then(({ data: products }) => {
      this.products = products;
      this.$store.state.products = products;
    });

    document.body.classList.add("tabs-background");

    document.addEventListener(
      "focus",
      ({ target }) => {
        if (target.matches(["input", "textarea"])) {
          const elContainer = target.closest(".container-web-app");
          if (!elContainer) {
            return;
          }

          const { product: productUrl } = elContainer.querySelector("[data-product]").dataset;

          if (!this.isProductActive(productUrl)) {
            this.$router.push(productUrl);
          }
        }
      },
      true
    );
  }
};
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.width--tight {
  max-width: 600px;
}

/*noinspection CssUnusedSymbol*/
.products {
  display: flex;
  flex-wrap: wrap;
}

/*noinspection CssUnusedSymbol*/
.products-list-move {
  transition: 0.25s;
}

/*noinspection CssUnusedSymbol*/
.products-list-enter {
  opacity: 0;
}

/*noinspection CssUnusedSymbol*/
.products-list-enter-active {
  transition: 0.25s;
}

/*noinspection CssUnusedSymbol*/
.products-list-leave-active {
  position: absolute;
  transition: 0.25s;
  opacity: 0;
}
</style>

<style>
@font-face {
  font-family: Poppins, sans-serif;
  src: local("Poppins"), url("../public/assets/fonts/Poppins/Poppins-SemiBold.ttf") format("truetype");
  font-weight: bold;
}

.font--poppins.font--poppins {
  font-family: Poppins, sans-serif !important;
}

/*noinspection CssUnusedSymbol*/
.back-button {
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
}

/*noinspection CssUnusedSymbol*/
.container-web-app {
  position: relative;
}

/*noinspection CssUnusedSymbol*/
.v-application .theme--dark.error--text {
  color: #ef9a9a !important;
}

/*noinspection CssUnusedSymbol*/
.link--dark {
  color: cyan !important;
}

/*noinspection CssUnusedSymbol*/
.v-expansion-panel--light {
  border: 1px solid lightgray;
}

/*noinspection CssUnusedSymbol*/
.v-expansion-panel--dark {
  border: 1px solid white;
}

/*noinspection CssUnusedSymbol*/
.slide-enter,
.slide-leave-to {
  max-height: 0;
}

.tabs-background {
  background-color: #ffffff;
}

/*noinspection CssUnusedSymbol*/
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.3s;
  overflow: hidden;
}

/*noinspection CssUnusedSymbol*/
.v-data-table,
.v-expansion-panel,
.v-list {
  transition: background-color 0.3s !important;
}

@media (prefers-color-scheme: dark) {
  .tabs-background {
    background-color: #121212;
  }

  [class*="--light"]:not([data-skip-dark]) {
    background: lightgray !important;
  }

  [data-button-external][class*="--light"] {
    border-color: unset;
  }
}

@media (prefers-reduced-motion: reduce) {
  *,
  ::before,
  ::after {
    animation-delay: -1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
    background-attachment: initial !important;
    scroll-behavior: auto !important;
    transition-duration: 0s !important;
    transition-delay: 0s !important;
  }
}
</style>
