<template>
  <div class="mb-2 description">
    <span v-html="description"></span>
    {{ usersCount ? `Has ${usersCount} user${isPlural ? "s" : ""} on Google Chrome.` : "" }}
  </div>
</template>

<script>
export default {
  name: "ProductDescription",
  props: {
    description: String,
    chromeItemUrl: String
  },
  data() {
    return {
      usersCount: 0
    };
  },
  computed: {
    isPlural() {
      return this.usersCount.replace(/\D/g, "") > 1;
    }
  },
  methods: {
    async getUserCount(url) {
      const { data } = await this.axios("/api/user-count", {
        params: { url }
      });
      return data.toString();
    }
  },
  async created() {
    if (this.chromeItemUrl) {
      this.usersCount = await this.getUserCount(this.chromeItemUrl);
    }
  }
};
</script>

<style>
.description {
  opacity: 0.8;
}
</style>
