import { render, staticRenderFns } from "./Screenshots.vue?vue&type=template&id=635c3001&"
import script from "./Screenshots.vue?vue&type=script&lang=js&"
export * from "./Screenshots.vue?vue&type=script&lang=js&"
import style0 from "./Screenshots.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_@vue+compiler-sfc@3.2.33_cache-loader@4.1.0_css-loader@3.6.0_vue-template-c_lvig2la6zzdinm6tu5vgv7idzq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/.pnpm/vuetify-loader@1.7.3_vue-template-compiler@2.7.14_vuetify@2.6.13_webpack@4.46.0/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VCarousel,VCarouselItem,VSheet})
