import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import routerDefaults from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import products from "../public/data/products.json";
import goTo from "vuetify/lib/services/goto";

import VueAxios from "vue-axios";
import bus from "./bus.js";
import VueGtag from "vue-gtag";

Vue.use(VueAxios, require("axios"));
Vue.use(require("vue-meta"));

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [...routerDefaults, ...products.reduce(toRoutes, [])]
});

Vue.use(
  VueGtag,
  {
    config: {
      id: "G-Y87WKHPRZX"
    }
  },
  router
);

Vue.prototype.$bus = bus;
const productsFullscreen = products.filter(getFullscreenProducts);

(function globallyRegisterFullscreenProducts() {
  for (const product of productsFullscreen) {
    const componentName = store.getters.toComponentName(product.pageUrl);
    Vue.component(componentName, () => import(`@/views/products/${componentName}.vue`));
  }
})();

const iProductsFullscreen = productsFullscreen.reduce(toKeyValuePairs, {});

Vue.config.productionTip = true;
const app = new Vue({
  router,
  async created() {
    this.$store.state.languageDefault = navigator.language;
  },
  watch: {
    $route(to, from) {
      if (this.isProductExternalOnly(to.path.substring(1))) {
        if (this.$store.state.activeTab !== 0) {
          this.$store.commit("setActiveTab", 0);
        }
        this.$store.commit("setProductActive", to.path.substring(1));
        this.$store.state.isAboutShow = false;
        return;
      }

      const isDefault = getIsDefaultPage(to.name);
      if (!this.$store.getters.isProductOpen(to.name) && !isDefault) {
        this.$store.commit("openProduct", to.name);
      }

      const iProductFullscreenTo = iProductsFullscreen[to.name];
      if (iProductsFullscreen[from.name] !== undefined) {
        this.$store.commit("closeProduct", from.name);
        this.$store.commit("setActiveTab", 0);
        if (iProductFullscreenTo !== undefined) {
          this.$store.commit("openProduct", to.name);
          this.$store.commit("setActiveTab", iProductFullscreenTo);
        }
        if (isDefault) {
          this.$store.commit("setProductActive", "");
        } else {
          this.$store.commit("setProductActive", to.name);
        }
        return;
      }

      if (iProductFullscreenTo !== undefined) {
        this.$store.commit("setActiveTab", iProductsFullscreen[to.name]);
        this.$store.commit("setProductActive", to.name);

        // Once the tab switch is finished, scrolling to the top
        new MutationObserver((_, observer) => {
          goTo(".container-web-app--fullscreen");
          const elBack = this.$el.querySelector(`[data-product="${to.name}"]`);

          const elContainerWebAppFullscreen = elBack.closest(".container-web-app--fullscreen");

          const elInput = elContainerWebAppFullscreen.querySelector("input[type=text], input[type=number]");
          elInput.focus();
          observer.disconnect();
        }).observe(document.querySelector(".v-window-item"), {
          attributes: true,
          attributeFilter: ["style"]
        });

        return;
      }

      if (to.name === "home") {
        const { productsOpen } = this.$store.getters;
        if (!this.$store.getters.productActive) {
          return;
        }

        const productLast = productsOpen[productsOpen.length - 1];
        // If navigating back to home, one product is
        // open and it was not the "leftover", i.e.
        // there was no previous product active before then
        if (productsOpen.length === 1 && productLast !== from.name) {
          const elProduct = this.$el.querySelector(`[data-product-container="${productLast}"]`);
          const elTextBox = elProduct.querySelector("input, textarea");
          if (elTextBox) {
            elTextBox.focus();
            return;
          }
          this.$router.push(productLast);
          return;
        }

        this.$store.commit("setProductActive", "");
        if (productLast === from.name) {
          this.$store.commit("closeProduct", from.name);
        }
        return;
      }

      if (to.name) {
        this.$store.commit("setProductActive", to.name);

        new MutationObserver((_, observer) => {
          const isProductOpen = this.$el.querySelector(`[data-product-container="${to.name}"] main`);

          if (!isProductOpen) {
            return;
          }

          goTo(`[data-product-container="${to.name}"]`, {
            easing: "easeInOutQuad",
            duration: 1000,
            offset: 20
          });
          observer.disconnect();
        }).observe(this.$el, { childList: true, subtree: true });
        return;
      }
      this.$router.push("/");
    }
  },
  methods: {
    isProductExists(pathName) {
      return products.some(product => product.pageUrl === pathName);
    },
    isProductExternalOnly(pathName) {
      return products.some(product => product.pageUrl === pathName && product.platforms[0].isExternal);
    }
  },
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");

(async function handleUrlRedirects() {
  // URL possibilities:
  // 1. Product exists, has an on-site algorithm
  // 2. Product exists, only has links
  // 3. Home/About
  // 4. Product doesn't exist

  const productUrl = location.href.replace(`${location.origin}/`, "").replace("#/", "");

  if (getIsProductExternalOnly(productUrl)) {
    app.$store.commit("setProductActive", productUrl);
    scrollWhenPossible(productUrl);
    return;
  }

  if (getIsProductExists(productUrl) || getIsDefaultPage(productUrl)) {
    const isPathJustHash = location.pathname === "/" && location.hash === "#/";
    if (isPathJustHash) {
      await app.$router.replace("/");
      return;
    }

    // If it's a full-screen product
    if (iProductsFullscreen[productUrl] > -1) {
      app.$store.commit("setActiveTab", iProductsFullscreen[productUrl]);
      app.$store.commit("setProductActive", productUrl);
      return;
    }

    if (getIsDefaultPage(productUrl)) {
      return;
    }

    // If it's a regular product
    app.$store.commit("openProduct", productUrl);
    app.$store.commit("setProductActive", productUrl);
    scrollWhenPossible(productUrl);
    return;
  }

  // If product doesn't exist
  await app.$router.replace("/");
  const isLikePath = location.pathname.match(/[\\/]/g).length >= 2;
  if (isLikePath) {
    location.reload();
  }
})();

function scrollWhenPossible(productUrl) {
  new MutationObserver((_, observer) => {
    const elContainerWebApp = app.$el.querySelector(`[data-product-container="${productUrl}"]`);
    if (!elContainerWebApp) {
      return;
    }
    observer.disconnect();
    goTo(elContainerWebApp, {
      easing: "easeInOutQuad",
      duration: 1000,
      offset: 20
    });
  }).observe(app.$el, { childList: true, subtree: true });
}

function getIsProductExists(product) {
  return app.isProductExists(product);
}

function getIsProductExternalOnly(product) {
  return app.isProductExternalOnly(product);
}

function getIsDefaultPage(toName) {
  // Must not be a default parameter
  toName = toName || "home";
  return routerDefaults.some(route => route.name === toName);
}

/**
 * @param {boolean} isExternal
 * @param {boolean} isFullscreen
 * @return {boolean}
 */
function getFullscreenProducts({
  platforms: {
    0: { isExternal, isFullscreen }
  }
}) {
  return !isExternal && isFullscreen;
}

/**
 * @param {Object} productsFullscreen
 * @param {string} pageUrl
 * @param {number} i
 * @return {Object}
 */
function toKeyValuePairs(productsFullscreen, { pageUrl }, i) {
  return { ...productsFullscreen, [pageUrl]: i + 1 };
}

/**
 * @param {Object} routes
 * @param {string} pageUrl
 * @param {Object[]} platforms
 * @return {Object}
 */
function toRoutes(routes, { pageUrl, platforms }) {
  const { isExternal } = platforms[0];
  const route = {
    path: `/${pageUrl}`,
    name: pageUrl
  };
  if (!isExternal) {
    const product = store.getters.toComponentName(pageUrl);
    route.components = () => import(`@/views/products/${product}.vue`);
  }
  routes.push(route);
  return routes;
}
