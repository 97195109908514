import Vue from "vue";
import Vuex from "vuex";
import download from "downloadjs";
import Vuetify from "../plugins/vuetify";
import { commaSeparated } from "@/store/utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    products: [],
    languageDefault: "",
    productActive: "",
    lastClosedByClick: "",
    productsOpen: [],
    activeTab: 0,
    screenshotsInfo: {
      isShow: false,
      product: {}
    },
    regex: {
      link: /\[(.*?)]\((.*?)\)/g,
      bold: /\*(.*)\*/g
    },
    servicesAvailable: (() => {
      const browserCurrent = (() => {
        if (navigator.userAgent.includes("Edg")) {
          return "edge";
        }
        if (navigator.userAgent.includes("Firefox")) {
          return "firefox";
        }
        if (navigator.userAgent.includes("OPR")) {
          return "opera";
        }
        if (navigator.userAgent.includes("Chrome")) {
          return "chrome";
        }
      })();

      const osCurrent = (() => {
        if (navigator.platform.match(/win/i)) {
          return "windows";
        }
        if (navigator.platform.match(/mac/i)) {
          return "mac";
        }
        if (navigator.platform.match(/linux/i)) {
          return "linux";
        }
      })();

      const platforms = [
        {
          platform: "mobile",
          name: "android",
          label: "Play Store",
          iconColor: "any",
          isPlatformUsed: os => navigator.userAgent.match(new RegExp(os, "i"))
        },
        {
          platform: "mobile",
          name: "ios",
          label: "App Store",
          iconColor: "any",
          isPlatformUsed: () => Boolean(navigator.platform) && navigator.platform.match(/iPad|iPhone|iPod/)
        },
        {
          platform: "browser",
          name: "browser",
          label: "Browser extension",
          iconColor: "black",
          isPlatformUsed: () => Boolean(navigator.platform) && !navigator.platform.match(/iPad|iPhone|iPod|Android/)
        },
        {
          platform: "browser",
          name: "chrome",
          label: "Chrome Web Store",
          iconColor: "any",
          isPlatformUsed: browser => browser === browserCurrent
        },
        {
          platform: "browser",
          name: "edge",
          label: "Edge Extension",
          iconColor: "any",
          isPlatformUsed: browser => browser === browserCurrent
        },
        {
          platform: "browser",
          name: "firefox",
          label: "Firefox Add-on",
          iconColor: "any",
          isPlatformUsed: browser => browser === browserCurrent
        },
        {
          platform: "browser",
          name: "opera",
          label: "Opera Add-on",
          iconColor: "any",
          isPlatformUsed: browser => browser === browserCurrent
        },
        {
          platform: "os",
          name: "windows",
          label: "Windows",
          iconColor: "any",
          isPlatformUsed: os => os === osCurrent
        },
        {
          platform: "os",
          name: "mac",
          label: "macOS",
          iconColor: "any",
          isPlatformUsed: os => os === osCurrent
        },
        {
          platform: "os",
          name: "linux",
          label: "Linux",
          iconColor: "any",
          isPlatformUsed: os => os === osCurrent
        },
        {
          platform: "github",
          name: "github",
          label: "GitHub",
          iconColor: "black",
          isPlatformUsed: platformName => platformName === "github"
        }
      ];

      const platformsTotalOrdered = [];
      let platformsCurrentIteration = [platforms[0]];

      for (let i = 1; i < platforms.length; i++) {
        const serviceCurrent = platforms[i];
        const serviceLastIteration = platformsCurrentIteration[platformsCurrentIteration.length - 1];

        if (serviceLastIteration.platform === serviceCurrent.platform) {
          // Always adding same-platform services to the array,
          platformsCurrentIteration.push(serviceCurrent);
        } else {
          // Once encountered a service with a different platform - time to add the current ones to the total array, in the correct order
          platformsTotalOrdered.push(...getOrderedPlatforms(platformsCurrentIteration));
          platformsCurrentIteration = [serviceCurrent];
        }
      }
      platformsTotalOrdered.push(...getOrderedPlatforms(platformsCurrentIteration));

      return platformsTotalOrdered;
    })()
  },
  getters: {
    productsFullscreen: ({ products }) => products.filter(product => product.platforms[0].isFullscreen),

    isProductActive:
      ({ productActive }) =>
      pageUrl =>
        productActive === pageUrl,

    isProductOpen:
      ({ productsOpen }) =>
      pageUrl =>
        productsOpen.includes(pageUrl),

    productsOpen: ({ productsOpen }) => productsOpen,
    productActive: ({ productActive }) => productActive,
    lastClosedByClick: state => state.lastCLosedByClick,

    languageDefault: state => state.languageDefault,

    getNumber:
      ({ languageDefault }) =>
      number => {
        const intl = new Intl.NumberFormat(languageDefault);
        return intl.format(number);
      },

    getOrderedServices:
      ({ servicesAvailable: platforms }) =>
      platformsAvailable => {
        const platformsExternal = platformsAvailable.filter(({ isExternal }) => isExternal);
        if (platformsExternal.length === 0) {
          return platformsAvailable;
        }

        // Iterate over the ordered services
        const platformsFinal = [];
        for (const platformOrdered of platforms) {
          // Check against each servicesOrdered if its servicesExternal service exists
          const serviceExternal = platformsExternal.find(platform => platform.name === platformOrdered.name);
          if (serviceExternal) {
            serviceExternal.label = platformOrdered.label;
            platformsFinal.push(serviceExternal);
          }
        }

        const isExternalOnly = platformsAvailable[0].isExternal;
        if (isExternalOnly) {
          return platformsFinal;
        }
        return [platformsAvailable[0], ...platformsFinal];
      },

    isDarkModeEnabled: (_, getters) => product => {
      if (!product.includes("-")) {
        product = getters.toPageUrl(product);
      }
      const { isDark } = Vuetify.framework.theme;
      return (!isDark && getters.isProductActive(product)) || (isDark && !getters.isProductActive(product));
    },

    toCommaSeparated:
      (_, getters) =>
      ({ number, language = getters.languageDefault }) => {
        const numberGroup = (() => {
          const numberFormat = new Intl.NumberFormat(language);
          const parts = numberFormat.formatToParts(1000);
          const iGroup = 1;
          return parts[iGroup].value;
        })();
        return number
          .toString()
          .replace(numberGroup, "")
          .replace(/\B(?=(\d{3})+(?!\d))/g, numberGroup);
      },

    toComponentName: () => pageUrl =>
      pageUrl
        .split("-")
        .map(string => string[0].toUpperCase() + string.substring(1))
        .join(""),

    toPageUrl: () => componentName => {
      const upperCaseRegex = /[A-Z]/g;
      const firstLowerCased = componentName.match(upperCaseRegex).map(letter => letter.toLowerCase());

      const wordsSplit = componentName.split(upperCaseRegex);
      // The array's first item is ""
      wordsSplit.shift();

      const words = wordsSplit.map((word, i) => firstLowerCased[i] + word);
      return words.join("-");
    },

    themeColorOpposite: ({ isDarkMode }) => (isDarkMode ? "white" : "black"),

    commaSeparated: () => number => commaSeparated(number)
  },
  mutations: {
    setProductsOpen: (state, products) => {
      state.productsOpen = products;
    },
    setLastClosedByClick: (state, product) => (state.lastCLosedByClick = product),

    openProduct: (state, productToOpen) => state.productsOpen.push(productToOpen),

    setProductActive: (state, productActive) => (state.productActive = productActive),

    setActiveTab: (state, i) => (state.activeTab = i),

    closeProduct: (state, productToClose) =>
      (state.productsOpen = state.productsOpen.filter(pageUrl => pageUrl !== productToClose)),

    setScreenshotsProduct: (state, product) => {
      state.screenshotsInfo.product = product;
      state.screenshotsInfo.isShow = true;
    },

    copy(_, text) {
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text).catch(() => copyToClipboardFallback(text));
      } else {
        copyToClipboardFallback(text);
      }
      // noinspection NestedFunctionJS
      function copyToClipboardFallback(text) {
        try {
          if (document?.queryCommandSupported("copy")) {
            const elTextarea = document.createElement("textarea");
            elTextarea.value = text;
            elTextarea.style.top = "0";
            elTextarea.style.left = "0";
            elTextarea.style.position = "fixed";
            elTextarea.style.opacity = "0";
            document.body.append(elTextarea);
            elTextarea.focus();
            elTextarea.select();
            try {
              document.execCommand("copy");
              // eslint-disable-next-line no-empty
            } catch {}
            elTextarea.remove();
          }
          // eslint-disable-next-line no-empty
        } catch {}

        try {
          window?.clipboardData?.setData("Text", text);
          // eslint-disable-next-line no-empty
        } catch {}
      }
    },
    generateServerCfg(state, { cvars, cvarValues, isAlign }) {
      const lines = [`// Generated by ${location.href}`, `// Generator developed by ${location.origin}`];

      if (location.pathname.includes("tf2")) {
        lines.push("// Full Team Fortress 2 server tutorial: https://www.youtube.com/watch?v=ix2qd__akf8");
      }

      const maxWidthText = { value: 0, cvar: 0 };
      const newLine = "\r\n";

      // Loop to setup the maxWidthText
      cvars.forEach(item => {
        if (item.title) {
          return;
        }

        switch (item.type) {
          case "number":
          case "text":
          case "email":
            maxWidthText.value = Math.max(maxWidthText.value, cvarValues[item.cvar]?.length ?? 0);
        }

        maxWidthText.cvar = Math.max(maxWidthText.cvar, item.cvar.length);
      });

      maxWidthText.value += 2;

      // Loop to iterate over each CVar to make the server.cfg
      cvars.forEach(item => {
        if (item.title) {
          lines.push(newLine, `// ${item.title}`);
          return;
        }

        const { cvar } = item;

        const spaces = getSpaces({
          item,
          value: cvarValues[cvar],
          maxWidthText
        });

        const tutorial = item.tutorial.replace("to On", "to 1").replace(state.regex.link, "$1 ($2)");

        const value = getValue({
          type: item.type,
          cvar,
          value: cvarValues[cvar]
        });

        const line = getFullLine({
          item,
          value,
          spaces,
          tutorial,
          maxWidthText,
          isAlign
        });

        lines.push(line);
      });

      lines.push("", "", "exec banned_user.cfg", "exec banned_ips.cfg", "writeid", "writeip");

      download(lines.join(newLine), "server.cfg", "text/plain");
    }
  },
  actions: {},
  modules: {}
});

function getOrderedPlatforms(platformsCurrentIteration) {
  const platformsCurrent = platformsCurrentIteration.filter(platform => platform.isPlatformUsed(platform.name));

  if (platformsCurrent.length === 0) {
    return platformsCurrentIteration;
  }

  // Place the currently-used platform at the beginning by removing it from the array
  const iPlatformsToRemove = platformsCurrentIteration
    .map((platform, i) => (platformsCurrent.some(platformCurrent => platformCurrent.name === platform.name) ? i : -1))
    .filter(iPlatform => iPlatform !== -1);

  platformsCurrentIteration = platformsCurrentIteration.filter((platform, i) => !iPlatformsToRemove.includes(i));

  return [...platformsCurrent, ...platformsCurrentIteration];
}

function getSpaces({ item, value = "", maxWidthText }) {
  const object = {
    cvar: " ".repeat(maxWidthText.cvar - item.cvar.length)
  };

  let length;

  switch (item.type) {
    case "text":
    case "email":
      length = value.length + 2;
      break;
    case "number":
      length = value.length;
      break;
    case "checkbox":
      if (item.cvar === "log") {
        length = value.length;
      } else {
        length = 1;
      }
      break;
    case "select":
      length = value.length;
  }

  object.value = " ".repeat(maxWidthText.value - length);

  return object;
}

function getValue({ type, value = "", cvar }) {
  switch (type) {
    case "text":
      return `"${value.replace(/"/g, `\\"`)}"`;

    case "email":
      return `"${value}"`;

    case "checkbox":
      if (cvar === "log") {
        return value ? "on" : "off";
      }
      return Number(value);

    default:
      return value;
  }
}

function getFullLine({ item, value, spaces, tutorial, maxWidthText, isAlign }) {
  switch (item.type) {
    case "text":
    case "email":
    case "number":
      return getLine({ item, value, spaces, tutorial, isAlign });

    case "select": {
      if (item.cvar === "sv_voiceenable") {
        const item = {
          cvar: "sv_use_steam_voice",
          type: "checkbox",
          defaultValue: 1
        };

        const spaces = getSpaces({ item, value, maxWidthText });

        const options = [
          {
            text: "Enable",
            value: 1
          },
          {
            text: "Disable",
            value: 0
          }
        ];

        const optionsText = `Options: ${getOptions(options)}`;

        return `${getLine({
          item,
          spaces,
          value,
          tutorial: "Enable/disable voice chat"
        })}. ${optionsText}`;
      }

      let optionsText;

      if (item.cvar === "log") {
        optionsText = "(on/off)";
      } else {
        optionsText = `Options: ${getOptions(item.options)} ${getSpecialValues(item.specialValues)}`;
      }

      return `${getLine({
        item,
        spaces,
        value,
        tutorial,
        isAlign
      })}. ${optionsText}`;
    }

    case "checkbox": {
      const match = tutorial.match(/allow|abl/) || ["abl"];
      let yes, no;
      switch (match[0]) {
        case "allow":
          yes = "Allow";
          no = "Disallow";
          break;

        case "abl":
          yes = "Enable";
          no = "Disable";
          break;
      }

      const values = {
        yes: item.cvar === "log" ? "on" : 1,
        no: item.cvar === "log" ? "off" : 0
      };

      const options = [
        {
          text: yes,
          value: values.yes
        },
        {
          text: no,
          value: values.no
        }
      ];

      const optionsText = `Options: ${getOptions(options)}`;

      return `${getLine({
        item,
        spaces,
        value,
        tutorial,
        isAlign
      })}. ${optionsText}`;
    }
  }
}

function getLine({ item, value, spaces, tutorial, isAlign }) {
  const specialValues = getSpecialValues(item.specialValues);

  // noinspection JSStringConcatenationToES6Template
  let line = item.cvar + " ";
  if (isAlign) {
    line += spaces.cvar;
  }

  // noinspection JSStringConcatenationToES6Template
  line += value + " ";

  if (isAlign) {
    line += spaces.value;
  }

  line += `// ${tutorial}${specialValues}`;
  return line;
}

function getSpecialValues(specialValues) {
  return (specialValues && `. Special values: ${getOptions(specialValues)}`) || "";
}

function getOptions(options) {
  return options.map(option => Object.values(option).join(" = ")).join(", ");
}
