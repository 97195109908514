var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"d-flex flex-wrap mt-2"},[_vm._l((_vm.platformsDisplayed),function(link){return _c('div',{key:link.name},[(link.isExternal)?_c('v-btn',{staticClass:"d-flex mr-2 mb-2 text-none button--font letter-spacing--tight",class:{
        'border--black': !_vm.isDarkModeEnabled,
        'border--gray': _vm.isDarkModeEnabled
      },attrs:{"href":link.url,"data-button-external":"","large":"","outlined":"","target":"_blank"}},[(link.name !== 'browser')?_c('img',{staticClass:"mr-2 platform__external",class:{
          'product__logo--white': _vm.isDarkModeEnabled && _vm.getExternalPlatformColor(link.name) === 'black'
        },attrs:{"src":_vm.getSrc(link, _vm.icon),"alt":""}}):_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.mdiPuzzle))]),_vm._v(" "+_vm._s(link.label)+" "),_c('v-icon',{staticClass:"ml-2",attrs:{"alt":"(launch)"}},[_vm._v(_vm._s(_vm.mdiLaunch))])],1):(!link.isFullscreen)?_c('v-btn',{staticClass:"mr-2 mb-2 text-none button--font letter-spacing--tight",class:{
        'border--black': !_vm.isDarkModeEnabled,
        'border--gray': _vm.isDarkModeEnabled
      },attrs:{"to":_vm.pageUrl,"data-button-external":"","large":"","outlined":""}},[_c('img',{staticClass:"mr-2 product__logo",class:{
          'product__logo--white': _vm.iconColor === 'white',
          'product__logo--black': _vm.iconColor === 'black'
        },attrs:{"src":_vm.getSrc(link, _vm.icon),"alt":""}}),_vm._v(" "+_vm._s(link.label)+" ")]):_c('v-btn',{staticClass:"mr-2 mb-2 text-none button--font letter-spacing--tight",class:{
        'border--black': !_vm.isDarkModeEnabled,
        'border--gray': _vm.isDarkModeEnabled
      },attrs:{"to":_vm.pageUrl,"large":"","outlined":""}},[_c('img',{staticClass:"mr-2 product__logo",class:{
          'product__logo--white': _vm.iconColor === 'white'
        },attrs:{"src":_vm.getSrc(link, _vm.icon),"alt":""}}),_vm._v(" "+_vm._s(link.label)+" ")])],1)}),(_vm.screenshotsInfo)?_c('v-btn',{staticClass:"text-none letter-spacing--tight button--font",class:{
      'border--black': !_vm.isDarkModeEnabled,
      'border--gray': _vm.isDarkModeEnabled
    },attrs:{"data-button-external":"","large":"","outlined":""},on:{"click":_vm.initializeScreenshots}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.mdiImageMultiple))]),_vm._v(" Screenshot"+_vm._s(_vm.screenshotsNumber > 1 ? "s" : "")+" ")],1):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }