<template>
  <v-container>
    <component :is="toComponentName(pageUrl)" />
    <v-btn :data-product="pageUrl" @click="onBackPress" @keypress.space="onBackPress" fab>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" :fill="colorArrow">
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
      </svg>
    </v-btn>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "ComponentFullscreen",
  props: {
    pageUrl: {},
    toComponent: {}
  },
  methods: {
    onBackPress() {
      this.$router.go(-1);
    }
  },
  computed: {
    ...mapGetters(["toComponentName"]),
    isDarkModeEnabled() {
      const { isDark } = this.$vuetify.theme;
      return isDark;
    },
    colorArrow() {
      return this.isDarkModeEnabled ? "#FFFFFF" : "";
    }
  }
};
</script>
