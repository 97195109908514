import Vue from "vue";
import VueRouter from "vue-router";
import App from "@/App";

Vue.use(VueRouter);
Vue.use(require("vue-meta"));

export default [
  {
    path: "/",
    name: "home",
    component: App
  }
];
