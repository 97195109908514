<template>
  <a
    :class="{
      'link--dark': darkMode,
      'link--light': !darkMode
    }"
    :href="href"
    target="_blank"
  >
    <slot />
  </a>
</template>

<script>
export default {
  name: "Url",
  props: {
    href: {
      type: String
    },
    darkMode: {
      type: Boolean,
      requireD: true
    }
  }
};
</script>
