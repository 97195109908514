<template>
  <header class="d-flex align-center">
    <img
      :class="{
        'product__logo--white': iconColor === 'white' && !isDarkModeEnabled,
        'product__logo--black': iconColor === 'black' && isDarkModeEnabled
      }"
      :src="`../assets/img/svg/${icon}.svg`"
      class="mr-2 product__logo"
      alt=""
    />

    <h3 class="flex-grow-1 mx-3 text-h5 font--bold font--poppins">
      {{ name }}
    </h3>

    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-icon
          v-on="on"
          :color="colorLink"
          aria-hidden="false"
          :aria-label="`Copy URL of ${name}`"
          role="button"
          @click="copyUrl"
        >
          {{ mdiLink }}
        </v-icon>
      </template>
      <span>Copy URL</span>
    </v-tooltip>
  </header>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import { mdiLink } from "@mdi/js";

export default {
  name: "ProductHeader",
  props: {
    icon: String,
    name: String,
    pathName: String,
    iconColor: String
  },
  data() {
    return {
      colorLink: "",
      mdiLink,
      timeoutColor: 2000
    };
  },
  computed: {
    ...mapGetters(["isProductActive"]),
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.pathName);
    },
    colorCopy() {
      return this.isDarkModeEnabled ? "#18FFFF" : "#5197aa";
    }
  },
  watch: {
    "$store.getters.productActive"() {
      this.applyColor();
    }
  },
  methods: {
    ...mapMutations(["copy"]),
    copyUrl() {
      const url = `${location.origin}/${this.pathName}`;
      this.copy(url);

      const { colorLink } = { ...this };

      this.colorLink = this.colorCopy;

      setTimeout(() => (this.colorLink = colorLink), this.timeoutColor);
    },
    applyColor() {
      this.colorLink = this.isDarkModeEnabled ? "#FFFFFF" : "#000000";
    },
    applyDarkMode() {
      const instanceDarkMode = matchMedia("(prefers-color-scheme: dark)");
      this.applyColor();

      // onchange instead of addEventListener() so it works cross-browsers
      instanceDarkMode.onchange = this.applyColor;
    }
  },
  created() {
    this.applyDarkMode();
  }
};
</script>

<style scoped>
.product__logo {
  max-width: 40px;
}

.font--bold {
  font-weight: 500;
}

/*noinspection CssUnusedSymbol*/
.product__link {
  width: 24px;
  min-width: 24px;
  cursor: pointer;
  transition: fill 0.25s ease-in-out;
}

/*noinspection CssUnusedSymbol*/
.product__logo--white,
.product__logo--black {
  filter: invert(100%);
}
</style>
