<template>
  <footer class="d-flex flex-wrap mt-2">
    <div v-for="link of platformsDisplayed" :key="link.name">
      <v-btn
        v-if="link.isExternal"
        :class="{
          'border--black': !isDarkModeEnabled,
          'border--gray': isDarkModeEnabled
        }"
        :href="link.url"
        class="d-flex mr-2 mb-2 text-none button--font letter-spacing--tight"
        data-button-external
        large
        outlined
        target="_blank"
      >
        <img
          v-if="link.name !== 'browser'"
          :class="{
            'product__logo--white': isDarkModeEnabled && getExternalPlatformColor(link.name) === 'black'
          }"
          :src="getSrc(link, icon)"
          alt=""
          class="mr-2 platform__external"
        />
        <v-icon v-else left>{{ mdiPuzzle }}</v-icon>
        {{ link.label }}

        <v-icon alt="(launch)" class="ml-2">{{ mdiLaunch }}</v-icon>
      </v-btn>

      <v-btn
        v-else-if="!link.isFullscreen"
        :class="{
          'border--black': !isDarkModeEnabled,
          'border--gray': isDarkModeEnabled
        }"
        :to="pageUrl"
        class="mr-2 mb-2 text-none button--font letter-spacing--tight"
        data-button-external
        large
        outlined
      >
        <img
          :class="{
            'product__logo--white': iconColor === 'white',
            'product__logo--black': iconColor === 'black'
          }"
          :src="getSrc(link, icon)"
          alt=""
          class="mr-2 product__logo"
        />
        {{ link.label }}
      </v-btn>

      <v-btn
        v-else
        :class="{
          'border--black': !isDarkModeEnabled,
          'border--gray': isDarkModeEnabled
        }"
        :to="pageUrl"
        class="mr-2 mb-2 text-none button--font letter-spacing--tight"
        large
        outlined
      >
        <img
          :class="{
            'product__logo--white': iconColor === 'white'
          }"
          :src="getSrc(link, icon)"
          alt=""
          class="mr-2 product__logo"
        />

        {{ link.label }}
      </v-btn>
    </div>

    <v-btn
      v-if="screenshotsInfo"
      :class="{
        'border--black': !isDarkModeEnabled,
        'border--gray': isDarkModeEnabled
      }"
      class="text-none letter-spacing--tight button--font"
      data-button-external
      large
      outlined
      @click="initializeScreenshots"
    >
      <v-icon left>{{ mdiImageMultiple }}</v-icon>
      Screenshot{{ screenshotsNumber > 1 ? "s" : "" }}
    </v-btn>
  </footer>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { mdiImageMultiple, mdiLaunch, mdiPuzzle } from "@mdi/js";

export default {
  name: "ProductLinks",
  props: {
    platforms: {},
    icon: String,
    pageUrl: String,
    iconColor: String,
    screenshotsInfo: Object
  },
  data() {
    return {
      mdiLaunch,
      mdiImageMultiple,
      mdiPuzzle
    };
  },
  computed: {
    ...mapGetters(["getOrderedServices"]),
    ...mapState(["servicesAvailable"]),
    platformsDisplayed() {
      if (!this.platforms) {
        return [];
      }

      return this.getOrderedServices(this.platforms);
    },
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.pageUrl);
    },
    screenshotsNumber() {
      const browserCurrent = this.platformsDisplayed[0].name;
      const screenshotsOfCurrentBrowser = this.screenshotsInfo.filenames.filter(filename =>
        filename.match(browserCurrent)
      );
      return screenshotsOfCurrentBrowser.length;
    }
  },
  methods: {
    getSrc(link, icon) {
      const iconName = link.isExternal ? link.name : icon;
      return `../assets/img/svg/${iconName}.svg`;
    },
    getExternalPlatformColor(externalPlatformName) {
      const service = this.servicesAvailable.find(service => service.name === externalPlatformName);
      return service.iconColor;
    },
    initializeScreenshots() {
      this.$store.commit("setScreenshotsProduct", this.screenshotsInfo);
      this.$store.commit("setProductActive", this.pageUrl);
    }
  }
};
</script>

<style scoped>
.letter-spacing--tight {
  letter-spacing: normal;
}

.platform__external {
  width: 20px;
}

.button--font {
  font-family: Arial, sans-serif;
}

.product__logo {
  width: 20px;
  height: 20px;
}

/*noinspection CssUnusedSymbol*/
.product__logo--white {
  filter: invert(100%);
}

/*noinspection CssUnusedSymbol*/
.border--black {
  border-color: rgb(153, 153, 153) !important;
}

/*noinspection CssUnusedSymbol*/
.border--gray {
  border-color: rgb(60, 60, 60) !important;
}

@media (prefers-color-scheme: dark) {
  /*noinspection CssUnusedSymbol*/
  .product__logo--black {
    filter: invert(100%);
  }
}
</style>
