import { render, staticRenderFns } from "./ProductDescription.vue?vue&type=template&id=6c3505b1&"
import script from "./ProductDescription.vue?vue&type=script&lang=js&"
export * from "./ProductDescription.vue?vue&type=script&lang=js&"
import style0 from "./ProductDescription.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/.pnpm/vue-loader@15.9.8_@vue+compiler-sfc@3.2.33_cache-loader@4.1.0_css-loader@3.6.0_vue-template-c_lvig2la6zzdinm6tu5vgv7idzq/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports